import { Component, ViewEncapsulation, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core'
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../app.service'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router'

// declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {

  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  name = null
  show = 0

  constructor(public auth: DataService,
    private router: Router,
    private modalService: NgbModal,
  ) {

  }


  async ngOnInit() {
    await this.auth.isLogIn()
    this.name = this.auth.login[0].username

  }

  async opensubmodal(contentstack, i) {
    this.modalService.open(contentstack, { size: 'lg' })
  }

  closesubmodal(contentstack) {
    this.modalService.dismissAll()
  }


  async logout() {
    await this.auth.logout()
    this.router.navigate([''])
    // console.log("logout")
  }


  open_web() {
    this.router.navigate(['/notifications'], {
      // queryParams: { id: e, name: name }
    })
  }
}
