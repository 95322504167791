import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FullComponent } from "./layouts/full/full.component";
import { LoginComponent } from "./login/login.component";
import { NotificationsComponent } from "./notifications/notifications.component";

export const Approutes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    data: {
      title: "Notifications Page",
    },
  },
  {
    path: "",
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/dashboard", pathMatch: "full" },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
      },

      {
        path: "master",
        loadChildren: () =>
          import("./master/master.module").then((m) => m.MasterModule),
      },
      {
        path: "qrcode",
        loadChildren: () =>
          import("./qrcode/qrcode.module").then((m) => m.QrcodeModule),
      },
      {
        path: "movement",
        loadChildren: () =>
          import("./movement/movement.module").then((m) => m.MovementModule),
      },
      {
        path: "checkin",
        loadChildren: () =>
          import("./checkin/checkin.module").then((m) => m.CheckinModule),
      },
      {
        path: "report",
        loadChildren: () =>
          import("./report/report.module").then((m) => m.ReportModule),
      },
      {
        path: "order",
        loadChildren: () =>
          import("./order/order.module").then((m) => m.OrderModule),
      },
      {
        path: "product",
        loadChildren: () =>
          import("./product/product.module").then((m) => m.ProductModule),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./user/user.module").then((m) => m.UserModule),
      },
      {
        path: "customer",
        loadChildren: () =>
          import("./customer/customer.module").then((m) => m.CustomerModule),
      },
      {
        path: "bill",
        loadChildren: () =>
          import("./bill/bill.module").then((m) => m.BillModule),
      },
      {
        path: "login_device",
        loadChildren: () =>
          import("./login_device/login_device.module").then(
            (m) => m.LoginDeviceModule
          ),
      },
      {
        path: "component",
        loadChildren: () =>
          import("./component/component.module").then(
            (m) => m.ComponentsModule
          ),
      },
      {
        path: "discount",
        loadChildren: () =>
          import("./discount/discount.module").then((m) => m.DiscountModule),
      },
      {
        path: "promotion",
        loadChildren: () =>
          import("./promotion/promotion.module").then((m) => m.PromotionModule),
      },
      {
        path: "link",
        loadChildren: () =>
        import("./link/link.module").then((m) => m.LinkModule),
    
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/dashboard",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
