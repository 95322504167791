import { Component, AfterViewInit, OnInit } from '@angular/core'
import { ROUTES } from './menu-items'
import { RouteInfo } from './sidebar.metadata'
import { Router, ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../app.service'

declare var $: any

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = ''
  showSubMenu = ''
  public sidebarnavItems: any[]
  data: any[]
  count: number = 0
  // this is for the open close
  async addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0'
    } else {
      this.showMenu = element
    }
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: DataService,

  ) { }

  // End open close
  async ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem)
    await this.auth.isLogIn()

    this.sidebarnavItems.forEach(item => {
      if (this.auth.login[0][item.group] === "1") item.show = true// console.log('ok naja')
    });

    this.sidebarnavItems = this.sidebarnavItems.filter(sidebarnavItem => sidebarnavItem.show === true)
  }

}
