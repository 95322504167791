<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
  <!-- nav-item d-none d-md-block d-lg-none -->
  <li class="nav-item d-none d-md-block ">
    <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
      href="javascript:void(0)">
      <i class="mdi mdi-menu font-24 text-white"></i>
    </a>
  </li>
  <!-- <li class="">
		<div class="m-t-15">
        	<a href="https://www.wrappixel.com/templates/monster-angular-dashboard/" class="btn btn-block btn-success text-white ml-3">Upgrade to Pro</a>
		</div>
    </li> -->
</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- <li class="nav-item pr-2 d-none d-md-block">
    <form class="app-search">
      <input type="text" class="form-control" placeholder="Search for..." />
      <a class="srh-btn"><i class="ti-search"></i></a>
    </form>
  </li> -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
      href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img src="assets/images/img/logout.jpg" alt="user" class="rounded-circle" width="40" />
    </a>

    <div class="dropdown-menu user-dd" ngbDropdownMenu>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item">
        ผู้ใช้งาน : {{name}}
      </a>
      <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
    </div>
  </li>



  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>

<div class="notification" style="margin-top: 6px" *ngIf="auth.noti>0">
  <div class="navbar-nav float-right ">
    <button class="btn btn-sm btn-danger" style="margin-top: 10px; margin-right: 5px; border-radius: 30px;"
      (click)="open_web()">
      <i class="mdi mdi-bell m-r-5 m-l-5" style="font-size: large; "></i>
      <span class="badge">{{auth.noti | number : '.0-2'}}</span>
    </button>
  </div>
</div>

<!-- <ng-template #contentstack let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">สินค้ามีจำนวนน้อยกว่าที่ต้องการ</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="table-responsive m-t-40">

   

      <table datatable name="myTable" id="myTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
        class="row-border hover">
        <thead>
          <tr>
            <th style="width:1%;">#</th>
            <th style="width:15%;">ประเภทสินค้า</th>
            <th style="width:15%;">แบรนด์สินค้า</th>
            <th style="width:10%;">ชื่อรุ่นสินค้า</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data ; let i = index">
            <td>{{i+1}}</td>
            <td>
              {{item.category_name}}
            </td>
            <td>
              {{item.brand_name}}
            </td>
            <td>
              {{item.model_name}}
            </td>
          </tr>
        </tbody>

      </table>


    </div>
  </div>
</ng-template> -->