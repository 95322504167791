<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <!-- <div class="row">
        <div class="col-md-6 mx-auto">
          <a class="navbar-brand" href="/" style="text-align: center;">
            
            <b class="logo-icon">
              <img
                src="assets/images/img/top-form.png"
                alt="homepage"
                class="dark-logo"
                style="width: 60%;"
              />
            </b>
          </a>
        </div>
      </div> -->
      <br />

      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <a class="navbar-brand" href="/" style="text-align: center;">
                  <!-- Logo icon -->
                  <b class="logo-icon">
                    <img src="assets/images/img/top-form.png" alt="homepage" class="dark-logo" />
                  </b>
                </a>
                <br />
                <br />
                <!-- <h1>Login</h1> -->
                <p class="text-muted">กรุณา Login เพื่อเข้าสู่ระบบ</p>
                <p *ngIf="status_login" class="category text-center" style="color: red">
                  Username Or Password Wrong
                </p>
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-user"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Username" autocomplete="username"
                    [(ngModel)]="username" required />
                </div>
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="icon-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Password" autocomplete="current-password"
                    [(ngModel)]="password" required />
                </div>
                <div class="row">
                  <div class="col-6">
                    <button type="button" class="btn btn-primary px-4 " style="background-color: blue;"
                      (click)="onSubmit()">
                      Login
                    </button>
                  </div>
                  <div class="col-6 text-right">
                    <!-- <button type="button" class="btn btn-link px-0">
                        Forgot password?
                      </button> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="card text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <div class="card-body text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <button type="button" class="btn btn-primary active mt-3">Register Now!</button>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</div>