<div class="row" style="margin: 5px;">
  <div class="col-lg-12">
    <div class="card" style="border-radius: 20px;">
      <div class="card-header" style="border-top-right-radius: 20px; border-top-left-radius: 20px;">
        <h4 style="margin-top: 15px;">
          <p>สินค้ามีจำนวนน้อยกว่าที่ต้องการ</p>
        </h4>
      </div>
      <div class="card-body">
        <table datatable name="myTable" id="myTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          class="row-border hover">
          <thead>
            <tr>
              <th style="width:1%;">#</th>
              <th style="width:10%;">ประเภทสินค้า</th>
              <th style="width:10%;">แบรนด์สินค้า</th>
              <th style="width:10%;">ชื่อรุ่นสินค้า</th>
              <th style="width:10%;">สินค้ามีจำนวนน้อยกว่า</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data ; let i = index">
              <td>{{i+1}}</td>
              <td>
                {{item.category_name}}
              </td>
              <td>
                {{item.brand_name}}
              </td>
              <td>
                {{item.product_model_name}}
              </td>

              <td>
                {{item.product_noti| number : '.0-2'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</div>