import { DataService } from '../app.service'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Http, Response } from '@angular/http'
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  username: any = null
  password: any = null
  status_login: number
  constructor(
    private http: Http,
    private auth: DataService,
    private router: Router
  ) { }

  async ngOnInit() {
    if (this.auth.isLoggedIn) {
      this.router.navigate(['dashboard'])
      return
    } else {
      localStorage.setItem('loggedIn', 'false')
      this.router.navigate([''])
    }
  }

  async onSubmit() {
    // await this.auth.getLogin("admin", "1234", null);
    // this.router.navigate(['dashboard'])
    await this.auth.getLogin(this.username, this.password, null)

    if (this.auth.login[0] != 0) {
      this.status_login = 0
      this.router.navigate(['dashboard'])
      return
    } else {
      this.status_login = 1
      localStorage.removeItem('loggedIn')
      return
    }
  }
}
