<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" [ngClass]="{'show-sidebar': showMobileMenu}" dir="ltr" data-theme="light" data-layout="vertical"
  [attr.data-sidebartype]="sidebartype" data-sidebar-position="absolute" data-header-position="fixed"
  data-boxed-layout="full">
  <!-- ============================================================== -->
  <!-- Topbar header - style you can find in topbar.scss -->
  <!-- ============================================================== -->
  <header class="topbar" data-navbarbg="skin1">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
      <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" data-logobg="skin6">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a (click)="showMobileMenu = !showMobileMenu"
          class="nav-toggler waves-effect waves-light d-block d-md-none text-dark" href="javascript:void(0)">
          <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
        </a>
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          <b class="logo-icon">

            <img src="assets/images/img/top-form.png" alt="homepage" class="dark-logo" style="width: 100%;" />

          </b>
        </a>
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- Toggle which is visible on mobile only -->
        <!-- ============================================================== -->


        <!-- <a class="topbartoggler d-block d-md-none text-dark waves-effect waves-light" href="javascript:void(0)"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <i class="ti-more"></i>
        </a> -->



        <!-- <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
          <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a> -->



      </div>


      <!-- <div *ngIf="this.sidebartype == 'mini-sidebar'">
        <div class="notification" style="margin-top: 6px" *ngIf="auth.noti>0 ">
          <div class="navbar-nav float-right ">
            <button class="btn btn-sm btn-danger" style="margin-top: 10px; margin-right: 5px; border-radius: 30px;">
              <i class="mdi mdi-bell m-r-5 m-l-5" style="font-size: large; "></i>
              <span class="badge">{{auth.noti}}</span>
            </button>
          </div>
        </div>
      </div> -->

      <ul *ngIf="this.sidebartype == 'mini-sidebar'" class="navbar-nav float-right">
        <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
          <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/img/logout.jpg" alt="user" class="rounded-circle" width="40" />
          </a>

          <div class="dropdown-menu user-dd" ngbDropdownMenu>

            <div class="dropdown-divider"></div>
            <a class="dropdown-item">
              ผู้ใช้งาน : {{name}}
            </a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
              <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
          </div>
        </li>
      </ul>

      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin6">
        <app-navigation (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation>
      </div>

    </nav>
  </header>
  <!-- ============================================================== -->
  <!-- Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <aside class="left-sidebar" data-sidebarbg="skin6" (mouseover)="Logo()" (mouseout)="Logo()">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar" [perfectScrollbar]="config">
      <!-- User profile -->
      <!-- <div class="user-profile">
	            User profile image
	            <div class="profile-img"> <img src="assets/images/users/1.jpg" alt="user" /> </div>
	            User profile text
	            <div class="profile-text" ngbDropdown display="static"> 
					<a href="javascript:void(0)" class="dropdown-toggle link u-dropdown" ngbDropdownToggle>Markarn Doe <span class="caret"></span></a>
	                <div class="dropdown-menu" ngbDropdownMenu>
	                    <a href="#" class="dropdown-item"><i class="ti-user"></i> My Profile</a>
	                    <a href="#" class="dropdown-item"><i class="ti-wallet"></i> My Balance</a>
	                    <a href="#" class="dropdown-item"><i class="ti-email"></i> Inbox</a>
	                    <div class="dropdown-divider"></div> <a href="#" class="dropdown-item"><i class="ti-settings"></i> Account
	                        Setting
	                    </a>
	                    <div class="dropdown-divider"></div> <a href="#" class="dropdown-item"><i class="fa fa-power-off"></i>
	                        Logout</a>
	                </div>
	            </div>
	        </div> -->
      <app-sidebar></app-sidebar>
      <!-- End Sidebar scroll-->
      <!-- <div class="sidebar-footer">
       
        <a href="" class="link" data-toggle="tooltip" title="Settings"
          ><i class="ti-settings"></i
        ></a>
       
        <a href="" class="link" data-toggle="tooltip" title="Email"
          ><i class="mdi mdi-gmail"></i
        ></a>
      
        <a href="" class="link" data-toggle="tooltip" title="Logout"
          ><i class="mdi mdi-power"></i
        ></a>
      </div> -->
    </div>
  </aside>
  <!-- ============================================================== -->
  <!-- End Left Sidebar - style you can find in sidebar.scss  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <app-breadcrumb></app-breadcrumb>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- footer -->
    <!-- ============================================================== -->
    <footer class="footer">
      พัฒนาโดย Topform-Dev
    </footer>
    <!-- ============================================================== -->
    <!-- End footer -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>