import { Component, OnInit, HostListener } from '@angular/core'
import { Router } from '@angular/router'

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { DataService } from '../../app.service'

declare var $: any

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {}
  name: any

  constructor(public router: Router, public auth: DataService) { }

  public innerWidth: any
  public defaultSidebar: any
  public showMobileMenu = false
  public expandLogo = false
  public sidebartype = 'full'

  Logo() {
    this.expandLogo = !this.expandLogo
  }

  async ngOnInit() {
    await this.auth.isLogIn()
    this.name = this.auth.login[0].username
    this.defaultSidebar = this.sidebartype
    this.handleSidebar()
    if (this.auth.isLoggedIn) {
      // this.router.navigate(['dashboard'])
      return
    } else {
      localStorage.setItem('loggedIn', 'false')
      this.router.navigate([''])
    }

    if (this.router.url === '/') {
      this.router.navigate(['/starter'])
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar()
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth
    if (this.innerWidth < 800) {//1170
      this.sidebartype = 'mini-sidebar'
    } else {
      this.sidebartype = this.defaultSidebar
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar'
        break

      case 'mini-sidebar':
        this.sidebartype = 'full'
        break

      default:
    }
  }

  async logout() {
    await this.auth.logout()
    this.router.navigate([''])
    // console.log("logout")
  }
}
