<nav class="sidebar-nav">
  <ul id="sidebarnav">
    <li class="nav-devider"></li>
    <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true" style="color: blue; font-size:medium;">
        <b> {{sidebarnavItem.title}}</b>
      </div>
      <a class="sidebar-link has-arrow waves-effect waves-dark"
        [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink;" (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'" href="javascript:void(0)">
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{sidebarnavItem.title}}
          <button class="btn btn-default btn-circle" style="width: 22px;
          height: 22px;
          /* border-radius: 15px; */
          text-align: center;
          padding: 1px 0;
          font-size: 14px;
          line-height: 1.428571429;
          color: white;
          background-color:red;
          " *ngIf="sidebarnavItem.title == 'จัดการใบสั่งซื้อ'">
            {{auth.count}} </button>
        </span>
      </a>
    </li>
  </ul>
</nav>

<!-- <nav class="sidebar-nav">
  <ul id="sidebarnav">
    <li class="nav-devider"></li>
    <li
      class="sidebar-item"
      [class.active]="showMenu === sidebarnavItem.title"
      *ngFor="let sidebarnavItem of sidebarnavItems"
      [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
    >
      <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
        {{sidebarnavItem.title}}
      </div>
      <a
        class="sidebar-link waves-effect waves-dark"
        [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
        [ngClass]="[sidebarnavItem.class]"
        *ngIf="!sidebarnavItem.extralink;"
        (click)="addExpandClass(sidebarnavItem.title)"
        [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
      >
      
        <i [ngClass]="[sidebarnavItem.icon]"></i>
        <span class="hide-menu">{{sidebarnavItem.title}}</span>
      </a>
    </li>
  </ul>
</nav> -->