import { RouteInfo } from "./sidebar.metadata";

export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "mdi mdi-home-outline",
    class: "",
    extralink: false,
    submenu: [],
    show: true,
    group: "dashboard_view",
  },

  // *************************************

  {
    path: "",
    title: "ใบสั่งซื้อ",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "order_view",
  },

  {
    path: "/bill/view1",
    title: "จัดการใบสั่งซื้อ",
    icon: "mdi mdi-account",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "order_view",
  },

  // *************************************
  {
    path: "",
    title: "Stock",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "stock_view",
  },

  {
    path: "/order/view1",
    title: "จัดการ Stock",
    icon: "mdi mdi-cart",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "stock_view",
  },
  // *************************************

  {
    path: "",
    title: "การเคลื่อนไหวสินค้า",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "movement_view",
  },

  {
    path: "/movement/view1",
    title: "การเคลื่อนไหวสินค้า",
    icon: "mdi mdi-file-document",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "movement_view",
  },

  // *************************************

  {
    path: "",
    title: "ข้อมูลสินค้า",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view1",
    title: "แบรนด์สินค้า",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view8",
    title: "สีแบรนด์สินค้า",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view9",
    title: "ชื่อรุ่นสินค้า",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view2",
    title: "ยาง",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view3",
    title: "ล้อแม็ก",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },
  {
    path: "/product/view4",
    title: "ช่วงล่าง",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },
  {
    path: "/product/view5",
    title: "ท่อไอเสีย",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },
  {
    path: "/product/view6",
    title: "Aerodynamic parts",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },
  {
    path: "/product/view7",
    title: "Accessories",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  {
    path: "/product/view11",
    title: "กำหนดจำนวนสินค้าแสดง",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "manage_qty_price_product_update",
  },

  {
    path: "/product/view12",
    title: "จัดการรูปสินค้า",
    icon: "mdi mdi-pencil",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "product_view",
  },

  // *************************************

  {
    path: "",
    title: "ข้อมูลรถยนต์",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "car_view",
  },

  {
    path: "/master/view1",
    title: "ประเภทรถยนต์",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "car_view",
  },

  {
    path: "/master/view2",
    title: "แบรนด์รถยนต์",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "car_view",
  },
  {
    path: "/master/view3",
    title: "รุ่นรถยนต์",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "car_view",
  },
  {
    path: "/master/view4",
    title: "รุ่นย่อยรถยนต์",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "car_view",
  },
  {
    path: "/master/view5",
    title: "เครื่องยนต์",
    icon: "mdi mdi-car",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "car_view",
  },

  // *************************************
  {
    path: "",
    title: "จัดการส่วนลด",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "discount_view",
  },

  {
    path: "/discount/view1",
    title: "ส่วนลดทุกกลุ่ม",
    icon: "mdi mdi-sale",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "discount_view",
  },

  {
    path: "/discount/view2",
    title: "ส่วนลดเฉพาะกลุ่ม",
    icon: "mdi mdi-sale",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "discount_view",
  },

  {
    path: "/discount/view3",
    title: "ส่วนลดยางปีที่แล้ว",
    icon: "mdi mdi-sale",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "discount_view",
  },

  // *************************************

  // *************************************
  {
    path: "",
    title: "จัดการข้อความโปรโมชั่น",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "promotion_text_view",
  },

  {
    path: "/promotion/view1",
    title: "โปรโมชั่น",
    icon: "mdi mdi-note-text",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "promotion_text_view",
  },

  // *************************************

  // *************************************

  {
    path: "",
    title: "จัดการลูกค้า",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "customer_view",
  },

  {
    path: "/customer/view1",
    title: "ลูกค้า",
    icon: "mdi mdi-account",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "customer_view",
  },

  {
    path: "/customer/view2",
    title: "กลุ่มลูกค้า",
    icon: "mdi mdi-account-key",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "customer_view",
  },

  // *************************************

  {
    path: "",
    title: "จัดการผู้ใช้งาน",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "user_view",
  },

  {
    path: "/user/view1",
    title: "ผู้ใช้งาน",
    icon: "mdi mdi-account",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "user_view",
  },

  {
    path: "/user/view2",
    title: "สิทธิ์การเข้าถึง",
    icon: "mdi mdi-account-key",
    class: "",
    extralink: false,
    submenu: [],
    show: false,
    group: "user_view",
  },

  // *************************************
  {
    path: "",
    title: "ตรวจสอบอุปกรณ์",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "login_device_view",
  },

  {
    path: "/login_device/view1",
    title: "ตรวจสอบอุปกรณ์",
    icon: "mdi mdi-tablet",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "login_device_view",
  },
  // *************************************

  {
    path: "",
    title: "คิวอาร์โค้ด",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "qrcode_view",
  },

  {
    path: "/qrcode/view1",
    title: "พิมพ์คิวอาร์โค้ด",
    icon: "mdi mdi-qrcode",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "qrcode_view",
  },

  // *************************************

  {
    path: "",
    title: "รายงาน",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "report_view",
  },

  {
    path: "/report/view1",
    title: "พิมพ์รายงาน",
    icon: "mdi mdi-file-document",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "report_view",
  },

  // *************************************

  {
    path: "",
    title: "เช็คอิน",
    icon: "mdi mdi-dots-horizontal",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "checkin_web_view",
  },

  {
    path: "/checkin/view1",
    title: "เช็คอิน",
    icon: "mdi mdi-map-marker",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "checkin_web_view",
  },

  // *************************************

  // *************************************
  {
    path: "",
    title: "CCS",
    icon: "mdi mdi-link",
    class: "",
    extralink: true,
    submenu: [],
    show: false,
    group: "dashboard_view",
  },

  {
    path: "/link/view1",
    title: "Link to CCS",
    icon: "mdi mdi-link",
    class: "",
    extralink: false,

    submenu: [],
    show: false,
    group: "dashboard_view",
  },
  // *************************************

  // {
  //   path: '',
  //   title: 'UI Components',
  //   icon: 'mdi mdi-dots-horizontal',
  //   class: 'nav-small-cap',
  //   extralink: true,
  //   submenu: []
  // },
  // {
  //   path: '/component/accordion',
  //   title: 'Accordion',
  //   icon: 'mdi mdi-equal',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/alert',
  //   title: 'Alert',
  //   icon: 'mdi mdi-message-bulleted',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/carousel',
  //   title: 'Carousel',
  //   icon: 'mdi mdi-view-carousel',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/dropdown',
  //   title: 'Dropdown',
  //   icon: 'mdi mdi-arrange-bring-to-front',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/modal',
  //   title: 'Modal',
  //   icon: 'mdi mdi-tablet',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/pagination',
  //   title: 'Pagination',
  //   icon: 'mdi mdi-backburger',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/poptool',
  //   title: 'Popover & Tooltip',
  //   icon: 'mdi mdi-image-filter-vintage',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/progressbar',
  //   title: 'Progressbar',
  //   icon: 'mdi mdi-poll',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/rating',
  //   title: 'Ratings',
  //   icon: 'mdi mdi-bandcamp',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/tabs',
  //   title: 'Tabs',
  //   icon: 'mdi mdi-sort-variant',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/timepicker',
  //   title: 'Timepicker',
  //   icon: 'mdi mdi-calendar-clock',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/buttons',
  //   title: 'Button',
  //   icon: 'mdi mdi-blur-radial',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/card',
  //   title: 'Card',
  //   icon: 'mdi mdi-arrange-bring-forward',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/toast',
  //   title: 'Toast',
  //   icon: 'mdi mdi-alert',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }
];
