import { Component, ViewEncapsulation, OnInit, ViewChild, EventEmitter, Output, ElementRef } from '@angular/core'
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../app.service'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router'
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();
  data: any = []
  select_data: any = []

  constructor(
    private auth: DataService,
    private router: Router
  ) { }

  async ngOnInit() {

    await this.get_data()

    this.dtOptions = {
      pagingType: 'full_numbers',
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  async get_data() {
    await this.auth.get_data({ do: 'get_product_noti' })
    this.select_data = this.auth.data
    this.data = this.select_data
  }


}
